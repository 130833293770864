import { atom } from 'recoil'

export const currentPlayState = atom({
  key: 'currentPlayState',
  default: {
    chainId: 1,
    tokenId: '',
    contractAddress: '',
    playStatus: false,
    outerPause: false,
  },
})
